<template>
    <div class="render_tree">
        <template v-if="Object.keys(data).length > 0 && data.data.data.length == 2">
            <button>{{ data.compareStocks[0].secname }}</button>    
            <div class="tree_one">
                <div><span>{{data.data.data[0].sName }}</span></div> 
                    <ul>
                        <li v-for="(item, index) in data.data.data[0].childData" :key="index">
                        <span>{{ item.sSecname }}</span>
                    </li>
                </ul>
            </div>
            <div class="tree_two">
                <div><span>{{data.data.data[1].sName }}</span></div>  
                <ul>
                    <li v-for="(item, index) in data.data.data[1].childData" :key="index">
                        <span>{{ item.sSecname }}</span>
                    </li>
                </ul> 
            </div>
        </template>
        <template v-else-if="Object.keys(data).length > 0 && data.data.data.length == 1">
            <button>{{ data.compareStocks[0].secname }}</button>    
            <div class="tree_one">
                <div><span>{{data.data.data[0].sName }}</span></div> 
                    <ul>
                        <li v-for="(item, index) in data.data.data[0].childData" :key="index">
                        <span>{{ item.sSecname }}</span>
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div class="noData">暂无数据</div>
        </template>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            data: {}
        }
    },
    methods: {
        init(commandData) {
            this.data = commandData
        }
    }   
}
</script>
<style lang="less" scoped>
.render_tree {
    
        
    margin:auto;
    // display: flex;
    padding-left: 100px;
    position: relative;
    > button {
        font-size: 32px;
        background: #FF5A37;
        border-radius: 32px;
        padding: 10px 38px;
        border: 0;
        margin-top: 40%;
        transform: translateX(-50%);
        color: #fff;
    }
    .noData {
        text-align: center;
        margin-left: -100px;
        margin-top: 48px;
        font-size: 24px;
    }
    .tree_one {
        display: flex;
        flex-direction: row;
        position: relative;
        transform: translateY(-120px);
        // &::before {
        //     content: " ";
        //     width: 1px;
        //     height: 50px;
        //     background: #8F3823;
        // }
            &::before {      
                content: " ";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 14px solid #FF5A37;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;     
                margin-left:-7px;
                margin-top: 45px;
                z-index: 999;
            } 
        > div {
            display: flex;
            position: relative;
            height: 58px;
            &::before {
                content: " ";
                width: 58px;
                height: 1px;
                background: #8F3823;
            }
            &::after {
                position: absolute;
                content: " ";
                width: 1px;
                height: 58px;
                background: #8F3823;
                // transform: rotate(90deg);
                left: 0;
                top: 0;
            }
            > span {
                font-size: 32px;
                line-height: 40px;
                margin-top: -25px;
                margin-left: 4px;
            }
        }
        > ul {
            display: flex;
            flex-direction: row;
            position: absolute;
            transform: translate(95px,-135px);
            &::after {      
                content: " ";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 14px solid #FF5A37;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;  
                margin-left:-7px;
                transform: translateY(87px);
                // margin-top:-65px ;
                z-index: 999;
            } 
            &::before {
                position: absolute;
                content: " ";
                width: 1px;
                height: 30px;
                background: #8F3823;
                transform: translateY(58px);
                left: 0;
                top: 0;
            }
            > li {
                display: flex;
                position: relative;
                height: 58px;
                &::before {
                    content: " ";
                    width: 100px;
                    height: 1px;
                    background: #8F3823;                    
                    transform: translate(0,58px);
                }
                &::after {
                    position: absolute;
                    content: " ";
                    width: 1px;
                    height: 30px;
                    background: #8F3823;
                    transform: translate(58px,28px);
                    left: 0;
                    top: 0;
                }
                > span {
                    width: 50px;
                    height: 150px;
                    font-size: 32px;
                    line-height: 32px;
                    margin-top: -102px;
                    margin-left: -55px;
                }
            }
        }
    }
   .tree_two {
        display: flex;
        flex-direction: column;
        position: relative;
        transform: translateY(-45px);
        &::after {      
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 14px solid #FF5A37;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;     
            margin-left:-7px;
            margin-top: -14px;
            z-index: 999;
        } 
        &::before {
            content: " ";
            width: 1px;
            height: 50px;
            background: #8F3823;
        }
        > div {
            display: flex;
            position: relative;
            height: 58px;
            &::before {
                content: " ";
                width: 58px;
                height: 1px;
                background: #8F3823;
            }
            // &::after {
            //     position: absolute;
            //     content: " ";
            //     width: 1px;
            //     height: 58px;
            //     background: #8F3823;
            //     // transform: rotate(90deg);
            //     left: 0;
            //     top: 0;
            // }
            > span {
                font-size: 32px;
                line-height: 40px;
                margin-top: -17px;
                margin-left: 4px;
            }
        }
        > ul {
            display: flex;
            flex-direction: row;
            position: absolute;
            transform: translate(95px,110px);
            &::after {      
                content: " ";
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 14px solid #FF5A37;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;     
                margin-left:-7px;
                margin-top: -14px;
                z-index: 999;
            } 
            &::before {
                position: absolute;
                content: " ";
                width: 1px;
                height: 30px;
                background: #8F3823;
                // transform: translateY(58px);
                left: 0;
                top: 0;
            }
            > li {
                display: flex;
                position: relative;
                height: 30px;
                &::before {
                    content: " ";
                    width: 100px;
                    height: 1px;
                    background: #8F3823;                    
                    transform: translate(0,30px);
                }
                &::after {
                    position: absolute;
                    content: " ";
                    width: 1px;
                    height: 30px;
                    background: #8F3823;
                    transform: translate(58px,30px);
                    left: 0;
                    top: 0;
                }
                > span {
                    width: 50px;
                    height: 150px;
                    font-size: 32px;
                    line-height: 32px;
                    margin-top: 60px;
                    margin-left: -55px;
                }
            }
        }
    }
}
</style>