<template>
    <div id="keChuangGraphChart">
        <div class="pages hidden" id="keChuangGraphPages">
            <page-title id="keChuangGraphChartText" ref="refPageTitle"></page-title>
            <div ref="refKeChuangGraphHolder" v-if="isKeChuangGraphVisible">
                <table-chart ref="refKeChuangGraphTable" id="KeChuangGraphTable" :customClass="currentData"/>
            </div>   
            <div ref="refAGuGraphInfoHolder" v-if="isAGuGraphInfoVisible" class="a_gu_graph_info_chart">
                <ul class="contrast_msg">
                    <li class="plate_item">
                        <div>关联公司数</div>
                        <div class ='item_value'>{{ commandData.data.data['关联公司数']}}</div>
                    </li>
                    <li class="plate_item">
                        <div>收盘价</div>
                        <div class ='item_value'>{{ commandData.data.data['收盘价']}}</div>
                    </li>
                    <li class="plate_item">
                        <div>涨跌幅</div>
                        <div class ='item_value'>{{ commandData.data.data['涨跌幅']}}</div>
                    </li>
                    <li class="plate_item">
                        <div>近一月涨跌幅</div>
                        <div class ='item_value'>{{ commandData.data.data['近一月涨跌幅']}}</div>
                    </li>
                </ul>
            </div>  
            <div ref="refGraphChirdInfoHolder" v-if="isGraphChirdInfoVisible" class="graph_chird_info_chart">
                <RenderTree ref="refRenderTree" id="renderTree" class="render_tree" />
            </div> 
            <div ref="refAssociatedCompanyHolder" v-if="isAssociatedCompanyVisible" class="associated_company_chart">
                <RenderTwoTree ref="refRenderTwoTree" id="renderTwoTree" class="render_two_tree" />
            </div>     
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import RenderTree from '../investmentMapChart/components/RenderTree.vue'
import RenderTwoTree from "./RenderTwoTree.vue";


export default {
    data:function(){
        return{
            isKeChuangGraphVisible:false,//申报情况
            isAGuGraphInfoVisible :false,
            isGraphChirdInfoVisible:false,
            isAssociatedCompanyVisible:false,
            currentData:[],
            commandData: {},

        }
    },
    components:{
        pageTitle,
        tableChart,
        RenderTree,
        RenderTwoTree,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isKeChuangGraphVisible = false;                
                this.isAGuGraphInfoVisible =false;                
                this.isGraphChirdInfoVisible =false,                
                this.isAssociatedCompanyVisible =false,
                resolve();
            });
        },
        async show(commandData){
                this.isKeChuangGraphVisible = false;               
                this.isAGuGraphInfoVisible =false;              
                this.isGraphChirdInfoVisible =false,              
                this.isAssociatedCompanyVisible =false,

            $("#keChuangGraphPages").removeClass("hidden");
            this.$refs.refPageTitle.show(commandData);
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["已申报科创板的公司","拟申报科创板的公司","三板达标的公司","(申报方)的详细介绍"].indexOf(commandData.subType) > -1){
                    this.handleKeChuangGraph(commandData);
                }else if (["(关联方)的关联情况"].indexOf(commandData.subType) > -1){
                    this.handleAGuGraphInfo(commandData);
                }else if (["(申报方)的关联图谱","(关联方)的关联图谱"].indexOf(commandData.subType) > -1){
                    this.handleGraphChirdInfo(commandData);
                }else if (["(申报方)的关联关系","(关联方)的关联关系"].indexOf(commandData.subType) > -1){
                    this.handleAssociatedCompany(commandData);
                }
            });
        },
/*==============================================*/
        handleKeChuangGraph(commandData){
            if(['三板达标的公司'].indexOf(commandData.subType) > -1){
                this.currentData = "chuang_graph_table_chart"
            }else if(["(申报方)的详细介绍"].indexOf(commandData.subType) > -1){
                this.currentData = "introduction_table_chart"
            }else{
                this.currentData = "ke_chuang_graph_table_chart"
            } 
            this.isKeChuangGraphVisible = true;
            this.$nextTick(()=>{
                this.renderKeChuangGraph(commandData);
            });
        },
        renderKeChuangGraph(commandData) {
            let width = this.$refs.refKeChuangGraphHolder.clientWidth;
            this.$refs.refKeChuangGraphTable.TableChart(commandData.data.data.chartData,{
                width: width,
                height: width * 0.77,
            });
        },
/*==============================================*/
        handleAGuGraphInfo(commandData) {
        this.isAGuGraphInfoVisible = true;
        this.$nextTick(() => {
            this.renderAGuGraphInfo(commandData)
        });
        },
        renderAGuGraphInfo(commandData) {
        this.commandData = commandData
        this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1000,
                    delay: 500,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();
            })
        },
/*==============================================*/
        handleGraphChirdInfo(commandData) {
        this.isGraphChirdInfoVisible = true;
        commandData.type="上游"
        this.$nextTick(() => {
            this.renderGraphChirdInfo(commandData)
        });
        },
        renderGraphChirdInfo(commandData) {
            this.$refs.refRenderTree.init(commandData)
        },
/*==============================================*/
        handleAssociatedCompany(commandData) {
        this.isAssociatedCompanyVisible = true;
        this.$nextTick(() => {
            this.renderAssociatedCompany(commandData)
        });
        },
        renderAssociatedCompany(commandData) {
            this.$refs.refRenderTwoTree.init(commandData)
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>